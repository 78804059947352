import React from "react"
import styled from "styled-components"

const FooterWrapper = styled.div`
  background-color: antiquewhite;
`

const Footer = () => {
  return <FooterWrapper></FooterWrapper>
}

export default Footer
